<script lang="ts" setup>
const store = useStore();

const colorMode = useColorMode();
const isDark = computed({
  get () { return colorMode.value === 'dark'; },
  set () { colorMode.preference = colorMode.value === 'dark' ? 'light' : 'dark'; }
});

const networks = [
  { name: "Mainnet", value: "mainnet" },
  { name: "Testnet", value: "testnet" },
];

watch(() => store.network, async () => {
  store.loadingBalance = true;
  await store.getBalance();
  store.loadingBalance = false;
});

</script>

<template>

  <div class="min-h-screen size-full flex justify-center pt-10 bg-gray-50/50 dark:bg-gray-900/40">
    <div class="flex flex-col w-full max-w-[400px] px-4 sm:px-0">

      <header class="flex flex-col items-center justify-between w-full">

        <div class="flex items-center justify-between mb-10 w-full">

          <h1
            class="
              font-display
              text-xl
              font-medium
              tracking-tight
              text-gray-950
              dark:text-gray-200">
              happycoin
          </h1>


          <!-- Network and theme selector -->
          <div class="flex items-center space-x-2">

            <USelect
              size="2xs"
              icon="i-mdi-web"
              v-model="store.network"
              :options="networks"
              option-attribute="name" />

            <UButton
              :icon="isDark ? 'i-heroicons-sun-20-solid' : 'i-heroicons-moon-20-solid'"
              color="white"
              size="xs"
              class="h-[24px] px-3"
              aria-label="Theme"
              @click="isDark = !isDark"
            />


          </div>

        </div>

        <Transition name="fade-in-up">
          <ConnectedWallet
            v-if="store.connectedAddr"
            :address="store.connectedAddr" />
        </Transition>


      </header>

      <slot />

      <div class="flex-1" />
      <AppFooter />

    </div>
  </div>

</template>

<style>
</style>
