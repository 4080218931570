<script lang="ts" setup>
</script>

<template>

  <footer class="flex items-baseline justify-between pt-4 pb-6">

    <span class="text-xs text-gray-500">© 2024 — Happy Coin</span>

    <nav class="flex space-x-4">

      <UButton
        color="white"
        icon="i-mdi-twitter"
        target="_blank"
        to="https://x.com/happycointrade">
      </UButton>

      <UButton
        color="white"
        icon="i-mdi-discord"
        target="_blank"
        square
        to="https://discord.gg/happyape">
      </UButton>

    </nav>

  </footer>

</template>

<style>
</style>
