<script lang="ts" setup>
defineProps<{
  address: string,
}>();

const store = useStore();
let   balanceInterval: NodeJS.Timeout | null = null;


onMounted(async () => {
  store.loadingBalance = true;
  await store.getBalance();
  store.loadingBalance = false;

  balanceInterval = setInterval(async () => {
    await store.getBalance();
  }, 10000);
});

onUnmounted(() => {
  if (balanceInterval) clearInterval(balanceInterval);
});
</script>

<template>

  <div class="inline-flex items-center space-x-2">

    <div
      class="
        flex items-center
        border border-gray-300 dark:border-gray-600 rounded-[3px]
        bg-white dark:bg-gray-900">

      <div class="text-xs flex py-0.5 px-2 border-r border-gray-200 dark:border-gray-700">
        <UIcon
          v-if="store.loadingBalance"
          name="i-icon-park-loading-one"
          class="text-sm animate-spin" />

        <span v-else class="font-mono tracking-tight font-medium">{{ store.balance }} SEI</span>
      </div>

      <span class="px-2 text-xs font-mono tracking-tight text-gray-700 dark:text-gray-300">{{ textEllipsis(address, 4) }}</span>

      <UButton
        size="xs"
        icon="i-mdi-logout"
        color="white"
        variant="ghost"
        class="px-2"
        @click="Auth.logout()" />

    </div>

  </div>

</template>

<style>
</style>
